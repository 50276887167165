<template>
  <div  v-click-outside="closeModalOutside" class="mt-1 z-40 absolute ml-4 px-8 py-6 base-bg rounded w-80 zoom-big-enter">
    <div class="relative">
      
      <closeModal @closeModal="closeModal"/>
    
      <h2 class="text-black font-sans font-bold antialiased text-xl mb-6">
        Sort Results 
      </h2>
      <div 
        v-for="item in items"
        :key="item.value"
        class="mb-4 border rounded base-border-color px-4 py-4 cursor-pointer"
        :class="{ 'text-black bg-white': localItem ==  item.value, 'disabled-text-grey': localItem !=  item.value}"
        @click="doSort(item.value)"
      >
        <p class="flex items-center text-sm font-medium"> 
          <component :is="item.icon" class="mr-4"></component>
          <span> {{ item.text }} </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import camera from '@/components/icons/camera'
  import calendar from '@/components/icons/calendar'
  import closeModal from '@/components/CloseModal'
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    components: {
      calendar,
      camera,
      closeModal
    },
    props:{
      isGlobal: {
        type: Boolean,
        default: true
      },
      items: Array,
      localSortItem: String
    },
    data(){
      return {
        localState: false
      }
    },
    computed:{
      ...mapGetters({
        sortItem: 'search/sortItem'
      }),
      localItem(){
        if(this.isGlobal) return this.sortItem
        else return this.localSortItem
      }
    },
    methods:{
      ...mapMutations({
        setSortItem: 'search/setSortItem'
      }),
      closeModal(){
        this.$emit('closeModal')
      },
      closeModalOutside(){
        if(this.localState) this.$emit('closeModal')
        this.localState = true
      },
      doSort(value){
        if(this.isGlobal){
          this.setSortItem(value)
        }else{
          this.$emit('sort', value)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>