<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="7" y="8.5" width="7" height="7" rx="1.61538" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 13.164L16.0089 14.4023C16.2095 14.5259 16.4612 14.5314 16.6669 14.4165C16.8725 14.3017 17 14.0846 17 13.849V10.151C17 9.91544 16.8725 9.69831 16.6669 9.58347C16.4612 9.46864 16.2095 9.47407 16.0089 9.59768L14 10.836" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="3" y="3" width="18" height="18" rx="5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>