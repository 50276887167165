<template>
  <div class="flex flex-wrap">
    <div class="flex flex-wrap justify-between items-center w-full h-12 border-b border-grey-light-200 flex items-center pl-12 pr-20">
      <breadcrumbs :active="isShowTopAdd && baseVideos.length ? 1 : 0"/>
      <div v-if="isShowTopAdd && baseVideos.length" class="flex items-center">
        <form 
          @submit.prevent
          class="flex"
        >
          <input 
            v-model="search"
            id="link" 
            name="link"  
            class="h-8 px-3 font-sans text-white text-sm bg-transparent rounded w-full text-sm text-black placeholder-white outline-none"
            placeholder="e.g. Title"
            @input="filterBySearch"
            required
          >
          <button
            type="submit"
            class="font-monoDemi text-sm base-font-gray-lighter-140 bg-transparent rounded-2xl border border-grey-light-150 px-4 py-1.5"
          >
            Search
          </button>
        </form>
        <div class="relative border-r pr-2.5 border-grey-light-160">
          <custom-button
            ButtonName="Dates"
            ButtonIcon="date-light"
            @click.native="showDateModal(true)"
            class="base-bg-darker-170 base-font-gray-lighter-140"
          />
          <Date 
            v-if="isDateShow"
            :isGlobal="false"
            :range="dateRange"
            @closeModal="showDateModal(false)" 
            @dates="filterVideosByDateRange"
            class="absolute right-0 w-125"
          />
        </div>
        <div class="relative">
          <custom-button
            ButtonName="Sort"
            ButtonIcon="sort-light"
            @click.native="showSortModal(true)"
            class="base-bg-darker-170 base-font-gray-lighter-140 ml-2.5"
          />
          <Sort 
            v-if="isSortShow"
            :isGlobal="false"
            :items="sortItems"
            :localSortItem="sortItem"
            @closeModal="showSortModal(false)" 
            @sort="sortByDate"
            class="absolute right-0"
          />
        </div>
        
      </div>
    </div>
    
    <!-- <VideosList 
      v-if="baseVideos.length"
      :videos="videos"
      :addedVideos="resultObjects"
      @changeVideoState="changeVideoState"
      @addAll="addAll"
      @deselectAll="deselectAll"
      @startAnalysisLocal="startAnalysisLocal"
      :commentersCounter="commentersCounter"
      :maximumCommenters="maximumCommenters"
      :loading="loading"
      :channelAvatar="channelAvatar"
      :addedChannels="addedChannels"
      :addData="addYouTubeData"
      ref="videoList"
    /> -->

    <Step1New 
      @matchYoutubeChannel="matchYoutubeChannel" 
      @matchYoutubeVideo="matchYoutubeVideo"
    />
  </div>
</template>

<script>
  // import Step1New from '@/components/DiscoveryProcess/Step1_old'
  import Step1New from '@/components/DiscoveryProcess/Step1'
  // import VideosList from '@/components/DiscoveryProcess/VideosList'
  import breadcrumbs from '@/components/breadcrumbs'
  import CustomButton from '@/components/CustomButton.vue'
  import Date from '@/components/search/drop-down/Date.vue'
  import Sort from '@/components/search/drop-down/Sort.vue'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import { warningToastConfig } from '@/helpers/constants'
  import { validateVideoURL, validateID } from '@/helpers/validaions'
  import { formatChannelURLToID } from '@/helpers/formats'
  import ToastMessages from '@/data/toast_messages.json'
  // import Hashids from 'hashids'

  export default {
    name: 'Discovery-Prosess-Step1',
    components: {
      Step1New,
      // VideosList,
      breadcrumbs,
      CustomButton,
      Date,
      Sort
    },
    data(){
      return{
        disabledFirstStep: false,
        isShowTopAdd: false,
        loading: false,
        channelAdded: false,
        videos: [],
        baseVideos: [],
        resultObjects: [],
        commentersCounter: 0,
        maximumCommenters: 1000,
        uniqueCommenters: [],
        channelAvatar: null,
        addedChannels: [],
        bucketId: null,
        search: '',
        sortItems: [
          {
            text: 'Newest First',
            value: 'desc',
            icon: 'calendar'
          },
          {
            text: 'Oldest First',
            value: 'asc',
            icon: 'calendar'
          }
        ],
        sortItem: '',
        isSortShow: false,
        isDateShow: false,
        dateRange: {}
      }
    },
    computed: {
      ...mapGetters({
        seedList: 'discovery/seedlist',
        bucket: 'bucket/bucket',
      })
    },
    methods: {
      ...mapActions({
        getVideo: 'discovery/getVideo',
        startAnalysis: 'discovery/startAnalysis',
      }),
      ...mapMutations({
        setChannelData: 'discovery/setChannelData',
        setAnalysisChannels: 'discovery/setAnalysisChannels',
      }),
      setBaseVideosList(){
        this.baseVideos = JSON.parse(JSON.stringify(this.videos))
      },
      filterVideosByDateRange(range){
        const { start, end } = range
        this.videos = this.baseVideos.filter(elem => this.$moment(elem.snippet.publishedAt).isBetween(this.$moment(start), this.$moment(end), 'days', '[]'))
        this.sortByDate(this.sortItem)
        this.showDateModal(false)
      },
      filterBySearch(){
        if(this.search.length){
          this.videos = this.baseVideos.filter(elem => {
            if(elem.snippet && elem.snippet.title) return elem.snippet.title.toLowerCase().indexOf(this.search) != -1
          })
        } else this.videos = JSON.parse(JSON.stringify(this.baseVideos))
      },
      sortByDate(value){
        this.sortItem = value
        if(value == 'desc') this.videos = this.videos.sort((a, b) => this.$moment(b.snippet.publishedAt).unix() - this.$moment(a.snippet.publishedAt).unix())
        else this.videos = this.videos.sort((a, b) => this.$moment(a.snippet.publishedAt).unix() - this.$moment(b.snippet.publishedAt).unix())
      },
      showDateModal(arg){
        if(arg){
          const localVideos = JSON.parse(JSON.stringify(this.baseVideos))
          const sorted = localVideos.sort((a, b) => this.$moment(b.snippet.publishedAt).unix() - this.$moment(a.snippet.publishedAt).unix())
          this.dateRange.start = sorted[0].snippet.publishedAt
          this.dateRange.end = sorted[localVideos.length - 1].snippet.publishedAt
        }
        this.isDateShow = arg
      },
      showSortModal(arg){
        this.isSortShow = arg
      },
      addYouTubeData(link){
        if(link.length){
          const fullArr = link.split(/\r?\n/);
          fullArr.forEach((data) => {
            if (data.includes('watch') || data.includes('embed')) {
              this.matchYoutubeVideo({ chan: data })
            } else {
              this.matchYoutubeChannel({ chan: data })
            }
          })
        }
      },
      matchYoutubeVideo(videoData) {
        this.bucketId = videoData.bucketId
        this.isShowTopAdd = true
        const isValidURL = validateVideoURL(videoData.chan)
        const isValidID = validateID(videoData.chan)
        if (isValidURL) {
          this.loading = true 
          this.getVideoData(isValidURL[1])
        } else if (isValidID) {
          this.loading = true 
          this.getVideoData(videoData.chan)
        } else {
          this.$toasted.show(ToastMessages.discovery.invalid_url, warningToastConfig)
        }
      },
      matchYoutubeChannel(channelData){
        this.bucketId = channelData.bucketId
        this.isShowTopAdd = true
        let isValidData = formatChannelURLToID(channelData.chan)
        let channelNameOrID = ''

        if(isValidData.length) channelNameOrID = isValidData
        else channelNameOrID = channelData.chan

        if (channelData.chan.includes('&list=')) {
          this.loading = true     
          this.getPlaylistData({data: {playlistId: channelData.chan.split('&list=')[1]}})
        } else if(channelNameOrID.length){
          this.loading = true     
          this.getChannelData(channelNameOrID)
        }else{
          this.$toasted.show(ToastMessages.discovery.invalid_url, warningToastConfig)
        }
      },
      async getPlaylistData(res) {
        this.channelAvatar = res.data ? res.data.thumbnail : "";
        this.addedChannels.push(res.data)

        this.startAnalysisLocal();

        const counter = 10
        // let response = await this.paginateItems(`https://yt.lemnoslife.com/playlistItems?part=snippet&playlistId=${res.data.playlistId}`)
        let response = await this.paginateItems(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${res.data.playlistId}&key=${process.env.VUE_APP_YOUTUBE_API}`)
        if (response.error) {
          if (response.error.code === 404) {
            this.$toasted.show('This channel is most likely removed from YouTube. Please try another channel.', warningToastConfig)
            return false
          }
        }
        this.videos = this.videos.concat(JSON.parse(JSON.stringify(response.items)))
        this.setBaseVideosList()


        for (let index = 0; index < counter; index++) {
          if(response.nextPageToken){
            // response = await this.paginateItems(`https://yt.lemnoslife.com/playlistItems?part=snippet&maxResults=50&playlistId=${res.data.playlistId}&pageToken=${response.nextPageToken}`)
            response = await this.paginateItems(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${res.data.playlistId}&key=${process.env.VUE_APP_YOUTUBE_API}&pageToken=${response.nextPageToken}`)
            this.videos = this.videos.concat(JSON.parse(JSON.stringify(response.items)))
          } else break
        }
        this.setBaseVideosList()
        this.sortByDate('desc')
        this.loading = false

      },
      getChannelData(channelNameOrID){
        this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get_chan/${channelNameOrID}`)
          .then(async res => {
            this.getPlaylistData(res);
          })
          .catch(() => {
            this.$toasted.show(ToastMessages.discovery.invalid_url, warningToastConfig)
            this.loading = false
          })
      },
      async paginateItems(link){
        return await fetch(link)
        .then(async res => {
            return await res.json()
          })
        .catch(err => {
          console.log(err, 'playlitItems')
          this.loading = false
        })
      },
      getVideoData(videoId){
        console.log(videoId, 'videoId')
        this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get_video/${videoId}`)
          .then(res => {
            this.videos.push(res.data)
            this.setBaseVideosList()
            this.loading = false
          })
          .catch(() => {
            this.$toasted.show(ToastMessages.discovery.invalid_url, warningToastConfig)
            this.loading = false
          })
      },
      async addVideo(video_id){
        let isPresent = this.seedList.find(elem => elem.video_id == video_id)
        if(isPresent){
          this.$toasted.show(ToastMessages.discovery.exsists, warningToastConfig)
        }else{
          this.loading = true
          try {
            await this.getVideo({video_id})
          } catch (error) {
            this.$toasted.show(error, warningToastConfig)
          } finally{
            this.loading = false
          }
        }
      },
      startAnalysisLocal() {
        this.loading = true;
        // const hashids = new Hashids();
        // const hash = hashids.encode(this.$moment().unix());
        // const channels = this.uniqueCommenters.map(elem => elem.authorId);
        // const seedlist = this.videos.map(elem => elem.video_id);

        console.log('this will display the added channels')
        console.log(this.addedChannels[0].channelId)

        this.startAnalysis({
          hash: this.addedChannels[0].channelId, 
          channels: this.addedChannels
        })
        let analysisChannels = this.resultObjects.map(elem => {
          return {
            channelName: elem.snippet.channelTitle,
            channelId: elem.snippet.channelId,
            thumbUrl: `https://img.youtube.com/vi/${elem.snippet.resourceId.videoId}/mqdefault.jpg`
          }
        })
        analysisChannels = [...new Map(analysisChannels.map((item) => [item["channelId"], item])).values()];
        this.setAnalysisChannels(analysisChannels)
        // this.setChannelData({
        //   channelId: this.resultObjects[0].snippet.channelId,
        //   channelTitle: this.resultObjects[0].snippet.channelTitle,
        //   channelAvatar: this.channelAvatar
        // })

        this.$router.push(`/discovery/jobs/${this.addedChannels[0].channelId}`)
      
        // this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/create-discovery-job`, {
        //   discoveryId: hash,
        //   // channels, // These are the author id's generated in 2&3.6
        //   // seedlist,
        //   bucketId: this.bucketId
        // })
        // .then((res) => {
        //   if(res.status == 'SUCCEEDED' || res.status == 200 || res.status.code == 503){
        //     this.loading = false
        //   } else {
        //     throw(ToastMessages.discovery.unexpected_error)
        //   }
        // })
        // .catch((error) => {
        //   if(error && error.response && error.response.status == 403) this.$toasted.show(ToastMessages.discovery.quota_exceeded, warningToastConfig)
        //   else if(error && error.response && error.response.status == 503){
        //     this.loading = false
        //     this.$router.push(`/discovery/jobs/${hash}`)
        //   }
        //   else this.$toasted.show(error, warningToastConfig)
        //   this.loading = false
        // })
      },
      async getCommentThreads(videoId){
        return await fetch(`https://www.googleapis.com/youtube/v3/commentThreads?part=id&part=replies&part=snippet&order=time&maxResults=100&videoId=${videoId}&key=${process.env.VUE_APP_YOUTUBE_API}`)
        .then(async res => {
          const result = await res.json()
          return result
        })
        .catch(error => {
          console.log(error, 'error')
          this.$toasted.show(error, warningToastConfig)
        })
      },
      async changeVideoState(videoId){
        const result = await this.getCommentThreads(videoId)
        if(result.error && (result.error.code != 200)) this.$toasted.show(result.error.message, warningToastConfig)
        else{
          let isPresent = this.resultObjects.find(elem => elem.videoId == videoId)
          let video = this.videos.find(elem => elem.snippet.resourceId.videoId == videoId)

          if(isPresent) {
            this.resultObjects = this.resultObjects.filter(elem => elem.videoId != videoId)
          }else{
            let resultObject =  this.checkCommenters(result.items, {
              videoId,
              active: true,
              commenters: []
            })
            this.resultObjects.push({...video, ...resultObject})
          }

          this.createCommentersArray(JSON.parse(JSON.stringify(this.resultObjects)))

          if('isAdded' in video) video.isAdded = !video.isAdded
          else video.isAdded = true

          this.videos = this.videos.map(elem => {
            if(elem.snippet.resourceId.videoId == video.snippet.resourceId.videoId) return video
            else return elem
          })
          this.setBaseVideosList()
        }
      },
      async addAll(items){
        for (let index = 0; index < items.length; index++) {
          if(this.commentersCounter == this.maximumCommenters){
            break
          }else {
            await this.changeVideoState(items[index].snippet.resourceId.videoId) 
          }
          
        }
      },
      async deselectAll(){
        this.resultObjects.forEach(async elem => {
          await this.changeVideoState(elem.snippet.resourceId.videoId) 
        })
      },
      checkCommenters(array, resultObject){
        let isCommenterPresent = ''
        let isCommenterPresentInReplies = ''
        let localResultObject = JSON.parse(JSON.stringify(resultObject))
        array.forEach((item) => {
          isCommenterPresent = localResultObject.commenters.find(elem => elem.authorId == item.snippet.topLevelComment.snippet.authorChannelId.value)
          if(isCommenterPresent){
            localResultObject.commenters = localResultObject.commenters.map(elem => {
              if(elem.authorId == isCommenterPresent.authorId){
                elem.count++
                return elem
              }else return elem
            })
          }else{
            localResultObject.commenters.push({
              authorId: item.snippet.topLevelComment.snippet.authorChannelId.value,
              count: 1
            })
          }

          if(item.replies && item.replies.comments && item.replies.comments.length){
            item.replies.comments.forEach(elem => {
              isCommenterPresentInReplies = localResultObject.commenters.find(item => item.authorId == elem.snippet.authorChannelId.value)
              if(isCommenterPresentInReplies){
                localResultObject.commenters = localResultObject.commenters.map(elem => {
                  if(elem.authorId == isCommenterPresentInReplies.authorId){
                    elem.count++
                    return elem
                  }else return elem
                })
              }else{
                localResultObject.commenters.push({
                  authorId: elem.snippet.authorChannelId.value,
                  count: 1
                })
              }
            })
          }
        })
        return localResultObject
      },
      createCommentersArray(array){
        let mergedArrays = []
        let uniqueCommenters = []
        let isPresent = null
        let countSumm = 0 

        array.forEach(elem => {
          if(elem.active) mergedArrays = mergedArrays.concat(elem.commenters)
        })
        mergedArrays.forEach(elem => {
          isPresent = uniqueCommenters.findIndex(item => item.authorId == elem.authorId)
          if(isPresent == -1) uniqueCommenters.push(elem)
          else uniqueCommenters[isPresent].count += elem.count
          isPresent = null
        })
        uniqueCommenters = uniqueCommenters.sort((a, b) => b.count - a.count)
        uniqueCommenters = uniqueCommenters.map(elem => {
          if(countSumm < this.maximumCommenters && (countSumm + elem.count) <= this.maximumCommenters){
            countSumm += elem.count
            return elem
          }else{
            return false
          }
        })
        if(countSumm == this.maximumCommenters){
          this.$toasted.show(ToastMessages.discovery.max_commenters_added, warningToastConfig)
        }
        uniqueCommenters = uniqueCommenters.filter(elem => elem != false)
        this.commentersCounter = countSumm
        this.uniqueCommenters = JSON.parse(JSON.stringify(uniqueCommenters))
      }
    }
  }
</script>

<style lang="scss" scoped>
.wrapper{
  height: calc(100% + 2.5rem);
}
.discoveryProcessBottom{
  width: calc(100% - 6rem);
  left: 3rem
}
</style>