<template>
  <div class="rounded-2xl bg-gray-100 h-8 ml-4 px-4 leading-8 cursor-pointer customButtonWrap" ref="buttonEl">
    <img v-if="ButtonIcon" class="inline-block w-5" :src="getImgUrl(ButtonIcon)" />
    <span class="font-mono text-sm rounded-sm bg-gray-50 px-1 select-none" v-if="!ButtonIcon">{{Selected}}</span>
    
    <span class="font-sans ml-2 text-sm select-none textVisibility">{{ ButtonName }}</span>

  </div>
</template>

<script>
export default {
  name: 'CustomButton',
  props: [
    'ButtonName', 'ButtonIcon', 'Selected'
  ],
  methods: {
    getImgUrl(icon) {
      return require('../assets/icons/' + icon + '.svg')
    },
    addWarning() {
      this.$refs.buttonEl.classList.add('warning');

      setTimeout(() => this.$refs.buttonEl.classList.remove('warning'), 1000)            

    }

  }

}
</script>

<style scoped lang="scss">
@media (max-width: 1120px) {
    .customButtonWrap{
      width: 4rem;
      text-align: center;
    }
    .textVisibility{
      display: none;
    }
  }

.warning {
  animation-name: pulse;
  animation-duration: .7s; 
  animation-timing-function: ease-in-out; 
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: none;
  animation-play-state: running; 
}

@keyframes pulse {
  0% {
    outline: 1px solid rgba(226, 226, 226, 0);
    background: rgba(243, 244, 246, .1);
  }
  50% {
    outline: 1px solid rgba(255, 227, 227, 0.832);
    color: rgb(255, 255, 255);
    background: rgba(243, 244, 246, .5);

  }
  100% {
    outline: 1px solid rgba(226, 226, 226, 0);
    color: black;
    background: rgba(243, 244, 246, .1);


  }
}
</style>